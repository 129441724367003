import React from 'react';

const Main = () => {
	const handleClick = () => {
		window.open('https://hop.clickbank.net/?affiliate=sp626&vendor=cortexi&pid=pre1', '_blank');
	};

	return (
		<section className="hero" id="main">
			<div className="container container-big py-2 py-xl-4">
				<div className="row gy-0 gy-md-0">
					<div className="col-md-6 col-lg-5 col-xl-6 order-sm-first order-last order-md-first d-none d-md-block">
						<img alt="" className="rounded img-fluid" src="https://trycortexi.com/assets/img/PROD-Hero.png" />
					</div>
					<div class="col-md-6 col-lg-5 col-xl-6 order-sm-last order-md-last d-xs-block d-md-none">
						<img alt="" class="rounded img-fluid d-block m-auto" src="https://trycortexi.com/assets/img/PRODx3.png" />
					</div>

					{/* This div will be displayed on screens larger than 576px */}
					<div className="col-md-6 col-lg-7 col-xl-6 d-md-flex align-items-md-center order-sm-last order-md-first d-none d-sm-block">
						<div className="hero-text-box">
							<div>
								<h1 className="main-heading text-white hero-heading">The Natural Tinnitus Relief Supplement That Works!</h1>
							</div>
							<h3 className="text-white hero-text">Cortexi the Herbal Science Breakthrough in Hearing Health</h3>
							<img alt="" className="img-fluid mx-sm-auto d-block mx-md-0" src="https://trycortexi.com/assets/img/certifications.png" />
							<button className="btn btn-secondary btn-lg cta-button d-block mx-sm-auto mx-md-0" onClick={handleClick}>
								LEARN MORE
							</button>
						</div>
					</div>

					{/* This div will be displayed on screens smaller than 768px */}
					<div className="col-md-6 col-lg-7 col-xl-6 d-md-flex align-items-md-center order-sm-last order-md-first d-block d-md-none">
						<div className="hero-text-box">
							<div>
								<h1 className="main-heading text-white hero-heading text-center">The Natural Tinnitus Relief Supplement</h1>
							</div>
							<img alt="" className="img-fluid mx-sm-auto d-block mx-md-0" src="https://trycortexi.com/assets/img/certifications.png" />
							<button className="btn btn-secondary btn-lg cta-button d-block mx-sm-auto mx-md-0" onClick={handleClick}>
								LEARN MORE
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* <section className="bg-primary p-2 pt-0">
				<div className="container">
					<h3 className="text-center vsl-heading-h3">
						Claim Your Discounted Cortexi
						<br />
						Below While Stock Lasts
					</h3>
				</div>
			</section> */}


		</section>
	);
};

export default Main;
