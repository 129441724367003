import React from 'react';
import '../index.css';

const Header = () => {
    return (
        <nav className="navbar navbar-dark navbar-expand-md text-uppercase py-3 header">
            <div className="container justify-center">
                <a className="navbar-brand d-flex align-items-center" href="#">
                    <img className="img-fluid topLogo" src="https://trycortexi.com/assets/img/Cortexi-2.png" alt="Logo"/>
                </a>
            </div>
        </nav>
    );
};

export default Header;
