import React from 'react';

const Faq = () => {
    return (
    
        
<div class="offer">
<div class="container container-big">
<div class="p-4 bg-primary triangle"></div>
<div class="row pt-5">
<div class="col-md-4 text-center mb-3 zoom  order-md-first order-sm-last order-sm-last order-last">
<div class="bottleArea ">
<div class="row">
<div class="col-sm-6 col-md-12 bottleTop">
<div class="bottleTitle">
<h1 class="mb-0">Try One</h1>
<h4 class="mt-0">30 Days, 1 Bottle</h4>
</div>
<img class="img-fluid" src="https://trycortexi.com/assets/img/PRODx1-500px.png" />
</div>
<div class="col-sm-6 col-md-12 addToCartArea">
<div class="bottlePrice">
<h2 class="display-4">$69</h2>
<span class="perBottle">Per<br/>Bottle<br/></span>
<div class="youSave">
<p>YOU SAVE $110!</p>
</div>
<a class="addToCart" data-sku="CTX01" data-price="69" href="https://hop.clickbank.net/?affiliate=sp626&vendor=cortexi&pid=pre1" target="_blank">Claim Offer</a>
<p class="money-back"><em>• 60 Day Money-Back Guarantee •</em></p>
<img class="img-fluid" src="https://trycortexi.com/assets/img/cards.png?h=7c547dcd262bc9ade62bb2fcad545fe3" />
<h3>
<s class="oldPrice mx-1">$179</s>
<span class="newPrice mx-1">$69<br/></span>
<span class="shipping">+SHIPPING&nbsp;</span>
</h3>
</div>
</div>
</div>
</div>
</div>
<div class="col-md-4 text-center mb-3 zoom order-md-1 order-sm-first order-first">
<div class="bottleArea  featured">
<div class="row">
<div class="col-sm-6 col-md-12 bottleTop">
<div class="bottleTitle">
<h1 class="mb-0">Best Value</h1>
<h4 class="mt-0">180 Days, 6 Bottles</h4>
</div>
<img class="img-fluid" src="https://trycortexi.com/assets/img/PRODx6-500px.png" />
</div>
<div class="col-sm-6 col-md-12 addToCartArea">
<div class="bottlePrice">
<h2 class="display-4">$49</h2>
<span class="perBottle">Per<br/>Bottle<br/></span>
<div class="youSave">
<p>YOU SAVE $780!</p>
</div>
<a class="addToCart" data-sku="CTX06" data-price="294" href="https://hop.clickbank.net/?affiliate=sp626&vendor=cortexi&pid=pre1" target="_blank">Claim Offer</a>
<p class="money-back"><em>• 60 Day Money-Back Guarantee •</em></p>
<img class="img-fluid" src="https://trycortexi.com/assets/img/cards.png?h=7c547dcd262bc9ade62bb2fcad545fe3"/>
<h3>
<s class="oldPrice mx-1">$1074</s>
<span class="newPrice mx-1">$294<br/></span>
<span class="shipping">US SHIPPING&nbsp;</span>
<span class="freeShiping">FREE</span>
</h3>
</div>
</div>
</div>
</div>
</div>
<div class="col-md-4 text-center mb-3 zoom order-md-last">
<div class="bottleArea ">
<div class="row">
<div class="col-sm-6 col-md-12 bottleTop">
<div class="bottleTitle">
<h1 class="mb-0">Most Popular</h1>
<h4 class="mt-0">90 Days, 3 Bottles</h4>
</div>
<img class="img-fluid" src="https://trycortexi.com/assets/img/PRODx3-500px.png" />
</div>
<div class="col-sm-6 col-md-12 addToCartArea">
<div class="bottlePrice">
<h2 class="display-4">$59</h2>
<span class="perBottle">Per<br/>Bottle<br/></span>
<div class="youSave">
<p>YOU SAVE $360!</p>
</div>
<a class="addToCart" data-sku="CTX03" data-price="177" href="https://hop.clickbank.net/?affiliate=sp626&vendor=cortexi&pid=pre1" target="_blank">Claim Offer</a>
<p class="money-back"><em>• 60 Day Money-Back Guarantee •</em></p>
<img class="img-fluid" src="https://trycortexi.com/assets/img/cards.png?h=7c547dcd262bc9ade62bb2fcad545fe3"/>
<h3>
<s class="oldPrice mx-1">$537</s>
<span class="newPrice mx-1">$177<br/></span>
<span class="shipping">US SHIPPING&nbsp;</span>
<span class="freeShiping">FREE</span>
</h3>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="text-center pb-4 pt-3"><img class="img-fluid paypal-img" src="https://trycortexi.com/assets/img/paypal-pay-later.jpg" /></div>
<div class="row">
<div class="col-9 col-sm-5 col-md-4 col-4 mx-auto d-block">
<div class="d-block mx-auto">
<h4 class="text-center">Our customers say</h4>
</div>
<div class="d-block mx-auto"><img class="img-fluid" src="https://trycortexi.com/assets/img/rating.png" /></div>
<div class="d-block mx-auto">
<p class="text-center">based on 16,200+ reviews!</p>
</div>
</div>
</div>
</div>
</div>










    );
};

export default Faq;
