import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Header2 from './components/Header2';
import Main from './components/Main';
import About from './components/About';
import Faq from './components/Faq';
import Offer from './components/Offer';
import Shipping from './components/Shipping';
import Ingredients from './components/Ingredients';
import Guarantee from './components/Guarantee';
import Reviews from './components/Reviews';
import Footer from './components/Footer';

//Pages
import EarsRinging from './pages/EarsRinging';
import HowICured from './pages/HowICured';

function App() {
    const structuredData = {
        '@context': 'http://schema.org',
        '@type': 'Product',
        image: 'https://trycortexi.com/assets/img/PROD-Hero.png',
        url: 'https://hop.clickbank.net/?affiliate=sp626&vendor=cortexi&pid=pre1',
        brand: {
            '@type': 'Brand',
            name: 'Cortexi',
            logo: 'https://trycortexi.com/assets/img/Cortexi-2.png',
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingCount: '16,200',
        },
    };

    useEffect(() => {
        ReactGA.initialize('G-XVQ8ECWY5S');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    <div className="App">
                        <Helmet>
                            <title>Cortexi - The Natural Tinnitus Relief Supplement That Works!</title>
                            <meta
                                name="description"
                                content="Cortexi is a natural supplement that can help to relieve tinnitus symptoms. It is safe and effective, and it has been shown to work in clinical studies."
                            />
                            <meta
                                name="keywords"
                                content="tinnitus relief, tinnitus supplement, tinnitus treatment, tinnitus cure, tinnitus medication, tinnitus help, tinnitus natural remedy, tinnitus home remedy, tinnitus support, tinnitus relief for seniors"
                            />
                        </Helmet>
                        <Header2 />
                        {/* <Header /> */}
                        <Main />
                        {/* <Offer /> */}
                        {/* <About /> */}
                        <Reviews />
                        {/* <Shipping /> */}
                        {/* <Ingredients /> */}
                        {/* <Guarantee /> */}
                        {/* <Faq /> */}
                        {/* <Footer /> */}
                    </div>
                } />
                <Route path="/why-are-my-ears-ringing" element={
                    <EarsRinging />
                } />
                <Route path="/how-i-cured-my-tinnitus" element={
                    <HowICured />
                } />

            </Routes>
        </Router>
    );
}

export default App;
